import React from "react";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  SelectTitle,
  useStyles,
  WrapperSelectList,
} from "./style";
import "../../../../App.css";
import { colors } from "../../../theme";

const SelectListFormsView = (props) => {
  const classes = useStyles();
  return (
    <WrapperSelectList>
      {props.type === "view" ? (
        <Container>
          <SelectTitle>{props.titleSelect}</SelectTitle>
          <FormControl
            style={{ width: "100%" }}
            required={props.required}
            error={props.error}
          >
            <InputLabel>{props.text}</InputLabel>
            {props.isMultiple ? (
              <Select
                id={props.id}
                variant={props.variant}
                value={props.selectOption || ""}
                multiple
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                input={<OutlinedInput label={props.text} />}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <span key={value.order}>
                        {value.label} {","}
                      </span>
                    ))}
                  </div>
                )}
              >
                {props.options?.map((value) => (
                  <MenuItem key={value.order} value={value}>
                    <Checkbox
                      checked={props.selectOption.indexOf(value) > -1}
                    />
                    <ListItemText primary={value.label} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                id={props.id}
                variant={props.variant}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                value={props.selectOption || ""}
                input={<OutlinedInput label={props.text} />}
              >
                {props.options?.map((value) => (
                  <MenuItem key={value.order} value={value}>
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <section className={classes.subtitle}>{props.subTitleSelect}</section>
        </Container>
      ) : (
        <Container>
          <ButtonContainer>
            <Button
              variant="text"
              onClick={props.onChangeNotSeeIntoPDF}
              style={{
                textTransform: "none",
                margin: "-12px 0 20px 0",
                backgroundColor: props.notSeeIntoPdfSelectRes && colors.gray3,
              }}
              startIcon={<VisibilityOffIcon />}
            >
              Not see into PDF
            </Button>
          </ButtonContainer>
          <ContainerTextField>
            <TextField
              placeholder="Click to Enter Title"
              fullWidth={true}
              variant="standard"
              onChange={props.onChangeTitleSelect}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
            />
          </ContainerTextField>
          <FormControl style={{ width: "100%" }}>
            <InputLabel>{props.text}</InputLabel>
            <Select
              disabled={props.disable}
              variant={props.variant}
              defaultValue={props.defaultValue}
              // onChange={props.onChange}
              value={props.selectOption}
              input={<OutlinedInput label={props.text} />}
            />
          </FormControl>
          <ContainerTextField>
            <TextField
              placeholder="Click to Enter Description"
              fullWidth={true}
              variant="standard"
              onChange={props.onChangeSubTitleSelect}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "lighter",
                },
              }}
            />
          </ContainerTextField>
          <ButtonContainerTools>
            <Button
              onClick={props.onChangeSettingSelect}
              style={{
                textTransform: "none",
                marginRight: "20px",
              }}
              variant={"text"}
              startIcon={<SettingsSuggestIcon />}
            >
              Settings
            </Button>
            <Button
              onClick={props.onChangeDeleteSelect}
              style={{
                textTransform: "none",
              }}
              color="error"
              variant={"text"}
              startIcon={<DeleteForeverIcon />}
            >
              Delete
            </Button>
          </ButtonContainerTools>
        </Container>
      )}
    </WrapperSelectList>
  );
};

export default SelectListFormsView;
