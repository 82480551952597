import React from "react";
import Button from "@mui/material/Button";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { colors } from "../../../theme";
import {
  ButtonContainerInferior,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DateWrap,
  DivHelperTextTitle,
  useStyles,
} from "./style";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DatePickerFormView = (props) => {
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {props.type !== "view" ? (
        <Container>
          <ButtonContainerInferior>
            <Button
              variant="text"
              onClick={props.onChangeNotSeeIntoPDFDp}
              style={{
                width: "175px",
                textTransform: "none",
                backgroundColor: props.notSeeIntoPdfDp && colors.gray3,
              }}
              startIcon={<VisibilityOffIcon />}
            >
              Not see into PDF
            </Button>
          </ButtonContainerInferior>
          <ContainerTextField>
            <TextField
              placeholder="Click to Enter Title"
              fullWidth={true}
              variant="standard"
              onChange={props.onChangeHelperText}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
            />
          </ContainerTextField>

          <DateWrap>
            <MobileDatePicker
              id={props.id}
              disabled={true}
              value={new Date()}
              renderInput={(params) => <TextField {...params} />}
              onChange={props.setDate}
            />
          </DateWrap>

          <ContainerTextField>
            <TextField
              placeholder="Click to Enter Description"
              fullWidth={true}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "lighter",
                },
              }}
              variant="standard"
              onChange={props.onChangeDescriptionText}
            />
          </ContainerTextField>
          <ButtonContainerTools>
            <Button
              onClick={props.onChangeSettingDateP}
              style={{
                textTransform: "none",
                marginRight: "20px",
              }}
              variant={"text"}
              startIcon={<SettingsSuggestIcon />}
            >
              Settings
            </Button>
            <Button
              onClick={props.onChangeDeleteDateP}
              variant={"text"}
              color="error"
              style={{
                textTransform: "none",
              }}
              startIcon={<DeleteForeverIcon />}
            >
              Delete
            </Button>
          </ButtonContainerTools>
        </Container>
      ) : (
        <Container>
          <DivHelperTextTitle>{props.helperText}</DivHelperTextTitle>
          <DateWrap>
            <MobileDatePicker
              id={props.id}
              label={props.label}
              disabled={props.disabled}
              disableFuture={props.disableFuture}
              disablePast={props.disablePast}
              inputFormat={props.inputFormat ? props.inputFormat : "MM/dd/yyyy"}
              value={props.field.value}
              onChange={props.setDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </DateWrap>
          <section className={classes.subtitle}>
            {props.descriptionText}
          </section>
        </Container>
      )}
    </LocalizationProvider>
  );
};

export default DatePickerFormView;
