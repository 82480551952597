import React from "react";
import ShortTextViewDocPlace from "./view";

const ShortTextDocPlace = ({
  getShortText,
  mode,
  type,
  label,
  placeholder,
  shortText,
  notSeeIntoPDF,
  deleteShortText,
  settingShortText,
  notSeeIntoPdfResponse,
  helperShortText,
  descriptionShortText,
  getHelpText,
  getDescriptionHelpText,
  disabled,
}) => {
  const onChangeShortText = (value) => {
    getShortText(value.target.value);
  };

  const onChangeNotSeeIntoPDF = () => {
    notSeeIntoPDF((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeDeleteShortText = () => {
    deleteShortText(true);
  };

  const onChangeSettingShortText = () => {
    settingShortText(true);
  };

  const onChangeHelpText = (value) => {
    getHelpText(value.target.value);
  };

  const onChangeDescriptionHelText = (value) => {
    getDescriptionHelpText(value.target.value);
  };

  return (
    <>
      <ShortTextViewDocPlace
        mode={mode}
        type={type}
        label={label}
        placeholder={placeholder}
        onChangeShortText={onChangeShortText}
        onChangeNotSeeIntoPDF={onChangeNotSeeIntoPDF}
        onChangeDeleteShortText={onChangeDeleteShortText}
        onChangeSettingShortText={onChangeSettingShortText}
        onChangeHelpText={onChangeHelpText}
        onChangeDescriptionHelText={onChangeDescriptionHelText}
        shortText={shortText}
        notSeeIntoPDF={notSeeIntoPdfResponse}
        helperShortText={helperShortText}
        descriptionShortText={descriptionShortText}
        disabled={disabled}
      />
    </>
  );
};

export default ShortTextDocPlace;
