import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../../theme";

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: "left",
    fontSize: "12px",
    fontWeight: "100",
    marginLeft: "12px",
    marginTop: "3px",
  },
}));

export const WrapperTextShort = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
  background-color: white;
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  height: auto;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainerTools = styled.section`
  position: absolute;
  bottom: -15px;
  right: 0;
`;

export const ContainerTextField = styled.div`
  width: 100%;
`;

export const DivHelperTextTitle = styled.div`
  margin-bottom: 10px;
  text-align: left;
  font-weight: 100 !important;
  font-size: 12px;
`;
