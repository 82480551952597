import React from "react";

import RadioButtonFormView from "./view";

const RadioButtonForm = ({
  type,
  name,
  size,
  disabled,
  item,
  row,
  notSeeIntoPDF,
  notSeeIntoPdfRadioB,
  deleteRadioButton,
  settingRadioButton,
  getTitleRadioButton,
  titleRadioB,
  getSubTitleRadioButton,
  subTitleRadioB,
  radioOption,
  getRadioB,
}) => {
  const onChangeRadioB = (event) => {
    const selectedValue = event.target.value;
    getRadioB(selectedValue);
  };
  const onChangeNotSeeIntoPDF = () => {
    notSeeIntoPDF((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeDeleteRadioB = () => {
    deleteRadioButton(true);
  };

  const onChangeSettingRadioB = () => {
    settingRadioButton(true);
  };

  const onChangeTitleRadioB = (value) => {
    getTitleRadioButton(value.target.value);
  };

  const onChangeSubTitleRadioB = (value) => {
    getSubTitleRadioButton(value.target.value);
  };

  return (
    <RadioButtonFormView
      size={size}
      name={name}
      disabled={disabled}
      item={item}
      row={row}
      type={type}
      onChangeRadioB={onChangeRadioB}
      radioOption={radioOption}
      onChangeNotSeeIntoPDF={onChangeNotSeeIntoPDF}
      notSeeIntoPdfRadioB={notSeeIntoPdfRadioB}
      onChangeDeleteRadioB={onChangeDeleteRadioB}
      onChangeSettingRadioB={onChangeSettingRadioB}
      onChangeTitleRadioB={onChangeTitleRadioB}
      onChangeSubTitleRadioB={onChangeSubTitleRadioB}
      titleRadioB={titleRadioB}
      subTitleRadioB={subTitleRadioB}
    />
  );
};

export default RadioButtonForm;
