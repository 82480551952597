import React from "react";
import CheckBoxView from "./view";

const CheckBox = ({
  defaultChecked,
  getCheckBoxSelect,
  checkBoxSelect,
  type,
  disabled,
  required,
  notSeeIntoPDF,
  notIntoPdfCheckB,
  deleteCheckBox,
  settingCheckBox,
  getTitleCheckBox,
  getSubTitleCheckBox,
  titleCheckBox,
  subTitleCheckBox,
  checkItem,
}) => {
  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      getCheckBoxSelect(e.target.value);
    }
  };

  const onChangeNotSeeIntoPDF = () => {
    notSeeIntoPDF((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeDeleteCheckBox = () => {
    deleteCheckBox(true);
  };

  const onChangeSettingCheckBox = () => {
    settingCheckBox(true);
  };

  const onChangeTitleCheckBox = (value) => {
    getTitleCheckBox(value.target.value);
  };

  const onChangeSubTitleCheckBox = (value) => {
    getSubTitleCheckBox(value.target.value);
  };

  return (
    <CheckBoxView
      type={type}
      required={required}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checkItem={checkItem}
      onChangeCheckBox={onChangeCheckBox}
      checkBoxSelect={checkBoxSelect}
      onChangeNotSeeIntoPDF={onChangeNotSeeIntoPDF}
      onChangeDeleteCheckBox={onChangeDeleteCheckBox}
      onChangeSettingCheckBox={onChangeSettingCheckBox}
      onChangeTitleCheckBox={onChangeTitleCheckBox}
      onChangeSubTitleCheckBox={onChangeSubTitleCheckBox}
      titleCheckBox={titleCheckBox}
      subTitleCheckBox={subTitleCheckBox}
      notSeeIntoPDF={notSeeIntoPDF}
      notIntoPdfCheckB={notIntoPdfCheckB}
    />
  );
};

export default CheckBox;
